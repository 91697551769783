import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { connect, useDispatch } from 'react-redux';
import {
  loadingToggleAction,
  resetErrors,
  signupAction,
} from '../../store/actions/AuthActions';
// image
import logo from "../../images/logo-full.webp";

function Register(props) {
  const [email, setEmail] = useState('');
  let errorsObj = { email: '', password: '' , name:'' , confirmpassword: "" };
  const [errors, setErrors] = useState({ email: '', password: '' , name:'' , confirmpassword: "" });
  const [password, setPassword] = useState('');
  const [name, setName] = useState("");
  const [confirmpassword , setConfirmPassword] = useState("")
  const dispatch = useDispatch();

  const [passerr , setpasserr] = useState(false)


  function onSignUp(e) {
    e.preventDefault();
    setpasserr(false)
    setErrors(errorsObj)
    let error = false;
    //const errorObj = { ...errorsObj };
    if(name ==''){
      setErrors((prev)=>{return {...prev ,name:"Name is required"}})
      error = true;
    }
    if (email === '') {
      setErrors((prev)=>{return {...prev ,email:"Email is required"}})
      error = true;
      //swal('Oops', errorObj.email, "error");
    }
    if (password === '') {
      setErrors((prev)=>{return {...prev ,password:"Password is required"}})
      error = true;
      //swal('Oops', errorObj.password, "error");
    }
    if (confirmpassword === '') {
      setErrors((prev)=>{return{...prev , confirmpassword:"Confirm password is required"}})
      error = true;
      //swal('Oops', errorObj.password, "error");
    }
    //setErrors(errorObj);
    if(password !== confirmpassword ){
      setpasserr(true)
    }
    if (error) return;
    //dispatch(loadingToggleAction(true));
    dispatch(signupAction( name ,email, password, confirmpassword ,  props.history));
  
}

useEffect(()=>{
  dispatch(resetErrors());
  setpasserr(false)
  setErrors(errorsObj)
},[name , password , confirmpassword , email])

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3" style={{width:"30%" , margin:"auto"}}>
                      <Link to="/login">
                        <img src={logo} alt="" style={{width: "100%"}} />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Sign up your account</h4>
                    { passerr ?  <div className="my-3 text-danger">Confirm Password doesn't Match</div> : props.errorMessage && (
                      <div className='my-3 text-danger'>
                        {props.errorMessage}
                      </div>
                    )}
                    {props.successMessage && (
                      <div className=''>
                        {props.successMessage}
                      </div>
                    )}
                    <form onSubmit={onSignUp}>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Name</strong>
                          <span className='text-danger'>*</span>

                        </label>
                        <input
                          defaultValue={name}
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          onChange={(e)=>setName(e.target.value)}
                        />
                      {errors.name && <div className="text-danger fs-12">{errors.name}</div>}
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Email</strong>
                          <span className='text-danger'>*</span>

                        </label>
                        <input
                          type="email"
                          defaultValue={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="form-control"
                          placeholder="Email"
                        />
                      {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Password</strong>
                          <span className='text-danger'>*</span>

                        </label>
                        <input
                        type="password"
                          defaultValue={password}
                          onChange={(e) =>
                            setPassword(e.target.value)
                          }
                          className="form-control"
                          placeholder="Password"
                        //defaultValue="Password"
                        />
                      {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Confirm Password</strong>
                          <span className='text-danger'>*</span>

                        </label>
                        <input
                        type="password"
                          defaultValue={confirmpassword}
                          onChange={(e) =>
                            setConfirmPassword(e.target.value)
                          }
                          className="form-control"
                          placeholder="Confirm Password"
                        //defaultValue="Password"
                        />
                      {errors.confirmpassword && <div className="text-danger fs-12">{errors.confirmpassword}</div>}
                      </div>
                      <div className="text-center mt-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          Sign me up
                        </button>
                      </div>
                    </form>
                    <div className="new-account mt-3">
                      <p className="">
                        Already have an account?{" "}
                        <Link className="text-primary" to="/login">
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Register);

