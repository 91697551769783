import { lazy, Suspense, useEffect, useState } from 'react';

/// Components
import Index from "./jsx";
import { connect, useDispatch, useSelector } from 'react-redux';
import {  Redirect, Route, Switch, withRouter } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import { logout } from './store/actions/AuthActions';
import { Spinner } from 'react-bootstrap';

const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const Login = lazy(() => {
    return new Promise(resolve => {
    setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
  });
});

function App (props) {
    const dispatch = useDispatch();

    let loading = useSelector((state)=>state.auth.showLoading)
    const [Routes , setRoutes] = useState(        
    <Switch>
    </Switch>)
    useEffect(() => {
        checkAutoLogin(dispatch, props.history);
        // if(window.location.pathname == "/" && !props.isAuthenticated ){
        //     window.location.href = '/login'
        // }
        //  logout(props)

    }, [dispatch, props.history ]);

    useEffect(()=>{
        console.log(props.isAuthenticated)
        if(!props.isAuthenticated){
            setRoutes(
                        <Switch>
                            <Route path='/login' component={Login} />
                            <Route path='/register' component={SignUp} />
                            <Route path='/page-forgot-password' component={ForgotPassword} />
                        </Switch>
                    )
        }
    },[props.isAuthenticated])
    if(loading){
        return <div style={{height:"100vh" , width:"100vw" , display:"flex" , justifyContent:"center" , alignItems:"center"}}><Spinner></Spinner></div>
    }else{

    
    if (props.isAuthenticated) {
		return (
			<>
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>  
                   }
                >
                    <Index/>
                </Suspense>
            </>
        );
	
	}else{
		return (
			<div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                  }
                >
                    {Routes}
                </Suspense>
			</div>
		);
	}
    }
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(App)); 

