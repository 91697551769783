import React, { Fragment, useState, useReducer, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Dropdown, Modal, Tab, Nav, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
//** Import Image */
import profile01 from "../../../../images/profile/1.jpg";
import profile02 from "../../../../images/profile/2.jpg";
import profile03 from "../../../../images/profile/3.jpg";
import profile04 from "../../../../images/profile/4.jpg";
import profile05 from "../../../../images/profile/5.jpg";
import profile06 from "../../../../images/profile/6.jpg";
import profile07 from "../../../../images/profile/7.jpg";
import profile08 from "../../../../images/profile/8.jpg";
import profile09 from "../../../../images/profile/9.jpg";
import profile from "../../../../images/profile/profile.png";
import { updateProfile } from "../../../../store/actions/AuthActions";
import PageTitle from "../../../layouts/PageTitle";

import { connect, useDispatch } from 'react-redux';



const initialState = false;
const reducer = (state, action) => {
  switch (action.type) {
    case 'sendMessage':
      return { ...state, sendMessage: !state.sendMessage }
    case 'postModal':
      return { ...state, post: !state.post }
    case 'linkModal':
      return { ...state, link: !state.link }
    case 'cameraModal':
      return { ...state, camera: !state.camera }
    case 'replyModal':
      return { ...state, reply: !state.reply }
    default:
      return state
  }
}

const AppProfile = (props) => {
  // const [state, dispatch] = useReducer(reducer, initialState);

  const dispatch = useDispatch()
  const [name, setName] = useState("")
  const [email, setEmail] = useState('')

  const profile = useSelector(state=> state)

  // alert(profile.auth.errorMessage)

  useEffect(()=>{
    setName(profile.auth.auth.user.name)
    setEmail(profile.auth.auth.user.email)
  },[])

  const options = {
    settings: {
      overlayColor: "#000000",
    },
  };


  const update_profile = (e) => {
    e.preventDefault();
    dispatch(updateProfile(name, email, props.history));
  }



  return (
    <Fragment>
      <PageTitle activeMenu="Update Profile" motherMenu="Dashboard" />
      <div className="authincation h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    {/* {profile.auth.errorMessage && <div style={{marginBottom: "10px"}}>{profile.auth.errorMessage}</div>} */}
                    <form onSubmit={update_profile}>
                      <div className="row">
                        {/* <div className="col-sm-6"> */}
                        <div className="form-group mb-3">
                          <label className="mb-1 ">
                            <strong>Full Name</strong>
                          </label>
                          <input type="name" className="form-control"
                            placeholder="Type Your Full Name"
                            value={name}
                            required
                            onChange={(e) => setName(e.target.value)}

                          />
                        </div>
                        {/* </div>  */}
                        {/* <div className="col-sm-6">
                                 <div className="form-group">
                                 <label className="mb-2 ">
                                          <strong>Last Name</strong>
                                        </label>
                                        <input type="name" className="form-control" 
                                          placeholder="Type Your Last Name"
                                        />
                                     </div>
                                     </div>         */}
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Email</strong>
                        </label>
                        <input type="email" className="form-control"
                          placeholder="Type Your Email Address"
                          value={email}
                          required
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      {/* <div className="form-group">
                                        <label className="mb-2 ">
                                            <strong>Set Password</strong>
                                            </label>
                                        <input type="password" className="form-control"
                                          placeholder="Type Your Password"
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label className="mb-2 ">
                                            <strong>Confirm Password</strong>
                                            </label>
                                        <input type="password" className="form-control"
                                          placeholder="Type Your Password"
                                        />
                                      </div>*/}
                      
                      <div className="col-sm-3 my-3">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          Update
                        </button>
                        {/* <input type="submit" className="btn btn-primary btn-block"/> */}
                      </div>
                    </form>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </Fragment>
  );
};

export default AppProfile;
