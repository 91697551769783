import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import {
  loadingToggleAction, loginAction,
} from '../../store/actions/AuthActions';

// image
import logo from "../../images/logo-full.webp";
import logoWhite from "../../images/logo-whiite-text.png";
import loginbg from "../../images/bg-login.jpg";
import {useSelector} from "react-redux"
import { resetErrors } from '../../store/actions/AuthActions';
function Login(props) {
  const [email, setEmail] = useState('');
  let errorsObj = { email: '', password: '' };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState('');
  // const [show , setShow] = useState('')

  const dispatch = useDispatch();

  // const reduxerr = useSelector((state)=> state) 
  // useEffect(()=>{
  //   console.log(reduxerr)
  //   if(reduxerr){
  //     setShow(true);
  //   }
  // })


  function onLogin(e) {
    e.preventDefault();
    dispatch(resetErrors());
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === '') {
      errorObj.email = 'Email is Required';
      error = true;
    }
    if (password === '') {
      errorObj.password = 'Password is Required';
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    //dispatch(loadingToggleAction(true));
    dispatch(loginAction(email, password, props.history));
    if(remember){

      localStorage.setItem("remember" , JSON.stringify({email , password}))
    }else{
      if(localStorage.getItem("remember")){
        localStorage.removeItem("remember")
      }
    }
  }


  useEffect(()=>{
    let user = localStorage.getItem("remember");

    if(user){
      setRemember(true)
      let parseduser = JSON.parse(user)
      setEmail(parseduser.email)
      setPassword(parseduser.password)
    }
    dispatch(resetErrors());
  },[])


  return (

    <div className="authincation h-100 p-meddle">
      {/* <div className="login-aside-left" > */}
      {/* <Link to={"#"} className="login-logo">
                        <img src={logo} alt="" width="50px"/>
                        <img src={logoWhite} alt="" className="ms-3"/>
                      </Link> */}
      {/* <div className="login-description">
                        <h2 className="main-title mb-2">Welcome To Invome</h2>
                        <p className="">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,</p>
                        <ul className="social-icons mt-4">
                            <li><Link to={"#"}><i className="fab fa-facebook-f"></i></Link></li>
                            <li><Link to={"#"}><i className="fab fa-twitter"></i></Link></li>
                            <li><Link to={"#"}><i className="fab fa-linkedin-in"></i></Link></li>
                        </ul>
                        <div className="mt-5 bottom-privacy">
                            <Link to={"#"} className="mr-4">Privacy Policy</Link>
                            <Link to={"#"} className="mr-4">Contact</Link>
                            <Link to={"#"} className="">© 20222 DexignLab</Link>
                        </div>
                    </div>
                </div> */}
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3" style={{width:"30%" , margin:"auto"}}>
                      <Link to="/login">
                        <img src={logo} alt="" style={{width:"100%"}} />
                      </Link>

                    </div>
                    <div className="text-center mb-3">
                      <h3 className="dz-title mb-1">Sign in</h3>

                      <p className="">Sign in by entering information below</p>
                    </div>
                    {props.errorMessage && (
                      <div style={{color:"red"}} className='bg-red-300 text-red-900 p-1 my-2'>
                        {props.errorMessage}
                      </div>
                    )}
                    {props.successMessage && (
                      <div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
                        {props.successMessage}
                      </div>
                    )}
                    <form onSubmit={onLogin}>
                      <div className="form-group mb-3">
                        <label className="mb-1">
                          <strong>Email</strong>
                          <span className='text-danger'>*</span>
                        </label>
                        <input type="email" className="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Type Your Email Address"
                        />
                        {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1"><strong>Password</strong>
                        <span className='text-danger'>*</span>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          value={password}
                          placeholder="Type Your Password"
                          onChange={(e) =>
                            setPassword(e.target.value)
                          }
                        />
                        {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                      </div>
                      <div className="form-row d-flex justify-content-between mt-4 mb-2">
                        <div className="form-group">
                          <div className="form-check custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              checked={remember}
                              className="form-check-input"
                              id="basic_checkbox_1"
                              onChange={(e)=> setRemember(e.target.checked)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="basic_checkbox_1"
                            >
                              Remember my preference
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          Sign In
                        </button>
                      </div>
                    </form>
                    <div className="new-account mt-2">
                      <p className="">
                        Don't have an account?{" "}
                        <Link className="text-primary" to="/register">
                          Sign up
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);
