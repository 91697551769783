import React, { useEffect, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import Card from "react-bootstrap/Card";
import Api from "../../../services/Api";
import { Spinner } from "react-bootstrap";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, IconButton, Snackbar, Slide as SlideTransition, Alert } from "@material-ui/core";


const PurchasedPlans = () => {

    const [packages, setPackages] = useState([])
    const [loading, setLoading] = useState(false)

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handlePage = (event, newPage) => setPage(newPage);
    const getPurchasedPlans = () => {
        setLoading(true)
        Api.get("users/plans/list")
            .then((res) => {
                setPackages(res.plan)
            })
            .catch(() => {

            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getPurchasedPlans()
    }, [])

    return (
        <>
            <PageTitle activeMenu="Purchased Plans" motherMenu="Dashboard" />
            <>
                <TableContainer>
                    <Table>
                        <TableHead className="thead">
                            <TableRow>
                                <TableCell className="th" component="th">Id</TableCell>
                                <TableCell className="th" component="th">License Number</TableCell>
                                <TableCell className="th" component="th">User Name</TableCell>
                                <TableCell className="th" component="th">Purchased Plan</TableCell>
                                <TableCell className="th" component="th">Number of Query</TableCell>
                                <TableCell className="th" component="th">Query Result</TableCell>
                                <TableCell className="th" component="th">Content Type</TableCell>
                                <TableCell className="th" component="th">Number Of Accounts</TableCell>
                                <TableCell className="th" component="th">Price</TableCell>
                                <TableCell className="th" component="th">Connected Accounts</TableCell>
                                <TableCell className="th" component="th">Purchased at</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className="tbody">

                            {loading ? <TableRow ><TableCell className="td" colSpan={100} scope="row" style={{ textAlign: "center" }}><br /><Spinner /><br /><br /></TableCell></TableRow>

                                :
                                <>
                                    {!packages?.length && <TableRow ><TableCell className="td" colSpan={100} scope="row" style={{ textAlign: "center" }}><br />No Plan Purchased<br /><br /></TableCell></TableRow>}
                                    {packages?.length > 0 && packages.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, i) => (
                                        <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                                            <TableCell className="td" scope="row">{item?.id}</TableCell>
                                            <TableCell className="td">{item?.license_number}</TableCell>
                                            <TableCell className="td">{item?.user.name}</TableCell>
                                            <TableCell className="td" scope="row">{item?.plan_name}</TableCell>
                                            <TableCell className="td" scope="row">{item?.no_of_query === -1? "Unlimted": item?.no_of_query }</TableCell>
                                            <TableCell className="td" scope="row">{item?.query_result}</TableCell>
                                            <TableCell className="td" scope="row">{item?.content_type === "Y"? "Yes": "No"}</TableCell>
                                            <TableCell className="td" scope="row">{item?.no_of_accounts}</TableCell>
                                            <TableCell className="td" scope="row">{item?.plan_price}</TableCell>
                                            {/* <TableCell className="td" scope="row">{JSON.stringify(item?.hs_account_id).slice(1, JSON.stringify(item?.hs_account_id).length -1)}</TableCell> */}
                                             <TableCell className="td" scope="row">{item?.hs_account_id.map((item)=>{
                                                return <div>{item}</div>
                                            })}</TableCell>
                                            <TableCell className="td">{item?.created_at}</TableCell>
                                        </TableRow>
                                    ))}
                                </>}
                        </TableBody>
                    </Table>
                </TableContainer>
                {packages?.length > 0 && <TablePagination
                    className="pagination"
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={packages.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePage}
                    onRowsPerPageChange={handleRowsPerPage}
                />}
            </>

        </>
    )
}

export default PurchasedPlans