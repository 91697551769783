import Config from '../config.json';
import axios from "axios";

class Api{

    config  = {};
    headers = {};
    accessToken = null;

    constructor(config = {}) {
        this.config = config;
        this.headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Max-Age': 600,
        };
    } 

    setToken = (token = null) => {
        this.config['accessToken'] = token;
    }

    prepareUrl = (path, lang_code) =>{
        path = path.trim();

        if( path.indexOf('://') !== -1 )
            return path;
        
        //path = path + (path.indexOf('?') !== -1 ? '&' : '?') + 'lang='+lang_code;

        return this.config.apiBaseUrl.trim()+path;
    }

    prepareParams = (method, path, conf = {}) =>{

        if( conf.token ){
            this.config['accessToken'] = conf.token;
            delete conf.token;
        }

        if( this.config.accessToken )
            this.headers['Authorization'] = 'Bearer '+this.config.accessToken.trim();
            //console.log(this.headers);
        var params = {
            url: this.prepareUrl(path,conf?.lang_code),
            method: method,
            headers: this.headers,
        };

        if( conf ){
            Object.keys(conf).forEach(key => {
                params[key] = conf[key];       
            });
        }
        return params;
    }

    get = async(path,conf = {}) => {
        return await this.call('get',path, null, conf);
    }

    post = async(path,data,conf = {}) => {
        return await this.call('post',path, data, conf);
    }

    put = async(path,data,conf = {}) => {
        return await this.call('put',path, data, conf);
    }

    delete = async(path,conf = {}) => {
        return await this.call('delete',path, null, conf);
    }

    call = async(method,path,data ={},conf = {}) => {
        
        // if( !this.config?.accessToken )
            this.config['accessToken'] = await localStorage.getItem("invotoken");

        var ret = {}, params = this.prepareParams(method, path, this.config.accessToken);
    
        try{ 
            
            if( data ) 
                params['data'] = data;

            //console.log("API CALL: "+method+" - Start ==========================");
            await axios(params).then((resp) => {
                ret =resp.data || {};
                
            }).catch((error) => {
                ret = { error:error.message || "Unable to process request" };
            });
           // console.log(ret);
           // console.log("API CALL: - END ==========================");
        } catch (err) {
            ret = {  error:"Unable to process request" };
        }
        
        return ret;
    }
}

export default new Api(Config);