import axios from 'axios';
import { Alert } from 'react-bootstrap';
import swal from "sweetalert";
import LifeTimeEarning from '../jsx/pages/WidgetBasic/LifeTimeEarning';
import {
    loginConfirmedAction,
    logout,
} from '../store/actions/AuthActions';

// const baseURL = 'http://analytico.demo.esteplogic.com/api/v1'
const baseURL = 'https://analytico-api.demo.esteplogic.com/api/v1'

export function signUp(name , email, password , password_confirmation) {
    //axios call
    const postData = {
        name,
        email,
        password,
        password_confirmation,
        returnSecureToken: true,
    };
    return axios.post(
        baseURL + "/register",
        postData,
    );
}

export function login(email, password) {
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        baseURL + "/login",
        postData,
    );
}

export function formatError(errorResponse) {
    switch (errorResponse.error.message) {
        case 'EMAIL_EXISTS':
            //return 'Email already exists';
            swal("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
            //return 'Email not found';
           swal("Oops", "Email not found", "error",{ button: "Try Again!",});
           break;
        case 'INVALID_PASSWORD':
            //return 'Invalid Password';
            swal("Oops", "Invalid Password", "error",{ button: "Try Again!",});
            break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    // tokenDetails.expireDate = new Date(
    //     new Date().getTime() + tokenDetails.expiresIn * 1000,
    // );
    localStorage.setItem("invotoken", tokenDetails.access_token);
}

export function runLogoutTimer(dispatch, timer, history) {
    setTimeout(() => {
        dispatch(logout(history));
    }, timer);
}

export function checkAutoLogin(dispatch, history) {
    const tokenDetailsString = localStorage.getItem('invotoken');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(logout(history));
        history.push('/login');

        return;
    }else{

        let token = localStorage.getItem("invotoken")
        dispatch({type:"loading_action" ,payload:""})
        
        let headers ={ 
                headers: {
                    "Content-Type": "application/json",
                    Authorization : `Bearer ${token}`
                }
        }
        axios.get(baseURL+ "/profile", headers)
        .then((res)=>{
            //console.log(res.data)
            let data = {
                user: res.data.user,
                access_token : localStorage.getItem("invotoken")
            }
            dispatch(loginConfirmedAction(data))
        }).catch(()=>{
            dispatch(logout(history));
            history.push('/login');

        })
    }

    // tokenDetails = JSON.parse(tokenDetailsString);
    // let expireDate = new Date(tokenDetails.expireDate);
    // let todaysDate = new Date();

    // if (todaysDate > expireDate) {
    //     dispatch(logout(history));
    //     return;
    // }
    // dispatch(loginConfirmedAction(tokenDetails));

    // const timer = expireDate.getTime() - todaysDate.getTime();
    // runLogoutTimer(dispatch, timer, history);
}

export function update_profile(name , email){

    let data = {
        name,
        email,
    }

    let token = localStorage.getItem("invotoken")

    let headers ={ 
        headers: {
            "Content-Type": "application/json",
            Authorization : `Bearer ${token}`
        }
}

    return axios.post( baseURL + "/update-profile" , data , headers )

}
export function update_password(old_password , password , password_confirmation ){

    let data = {
        old_password,
        password,
        password_confirmation
    }

    let token = localStorage.getItem("invotoken")

    let headers ={ 
            headers: {
                "Content-Type": "application/json",
                Authorization : `Bearer ${token}`
            }
    }
    return axios.post(baseURL + "/change-password" , data , headers)
}

export function logout_user(){
    let token = localStorage.getItem("invotoken")

    let headers ={ 
            headers: {
                "Content-Type": "application/json",
                Authorization : `Bearer ${token}`
            }
    }
    return axios.get(baseURL + "/logout" , headers)
}


export function user_access(data){
    let token = localStorage.getItem("invotoken")

    let headers ={ 
            headers: {
                "Content-Type": "application/json",
                Authorization : `Bearer ${token}`
            }
    }
    return axios.post("https://analytico-api.demo.esteplogic.com/api/v1/hubspot/connect", {code: data , redirect_url :"http://localhost:3000"} ,{
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMGI0OTMzOThjZTdmMTljM2U3OGE1ZWI0NDdhM2UyYjg0MjU4MThjZmRhMzY5M2U4MWRiZDc1ZDQwMjg5NzdjMDhhNmViNzIxMzBkMDczNDAiLCJpYXQiOjE2NzExNzMwOTIsIm5iZiI6MTY3MTE3MzA5MiwiZXhwIjoxNzAyNzA5MDkyLCJzdWIiOiI0NyIsInNjb3BlcyI6W119.wez5AAKln3fFJgdgg4uT121k1HJToDjluszWyydwBxqw5T9S7zkMjUgDs78U03byEJdAyZ2SBULssiIF46lXjurihWgrXEdEanxRZ7z4K00Fow1qJSAU_Ak3lZagP3hStr7mdVRJFCbFwCW03_Etj8-b1Ljw2FwgDu6kNcjr0fM_dlNymUngbqcNebl0LaHylfufZHkNRm-lMDb9enfOCg-YE5JOc3IedcqUjUg3xzpLRkBsthIkE_73l0a_nqdKrNbSCFM3AnjUK3AJJR82ZpNDsr8mJraY0iRRh1t6-YR0f7Ex2pbY-im2XCeWm7m5upPHJmKp018ZtlHyUe4ZwPKGkrfFMJCkpQH-vG9lcn09XptB0lsI24aQObIitrTfdyovPK_QAjyt0xH4rqFq52nFr0iyrdmUiWDkZmmWP_qC3qTW3LRJkJOKYy9313TCrxiCTrF_xDe550Uty1jZrR__JDwKOyJW21txSaK5tfaWMmBcQ3Y0F_NLRLRzteriv4k0EwDiXnaIVzsoGEX6CAjz_JhhnxrXL_lyZ-G98xtriybdpfG2QVKL4F7lNAlhuP7aM4DDShzCuZ9d8bmXUxaDTJhus2AR3io-InJDiTqpdnhHwUoLB0tvj6yKYZjQ9-TBbORBXKjomWsKuJRiYR0MM-RhRgJw12XwyZICWOQ`
            //Authorization: `Bearer ${token}`
        } })
    .then((res)=>{
        return res
        
    }).catch((err)=>{
        // alert(JSON.stringify(err))
        return err

    })
}