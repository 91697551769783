import React, { useEffect, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import Card from "react-bootstrap/Card";
import Api from "../../../services/Api";
import { Spinner, Modal , Button ,Alert } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import { Link } from "react-router-dom";
import { AddBox as AddIcon, Delete as DeleteIcon , PersonOff as DisabledUser , Person as EnabledUser} from '@mui/icons-material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, IconButton, Snackbar, Slide as SlideTransition } from "@material-ui/core";
import { useSelector , useDispatch } from "react-redux";
const PurchasedPlans = () => {
    let users = useSelector(state => state.auth.auth.user)
    const dispatch = useDispatch()
    const [packages, setPackages] = useState([])
    const [popup, setPopup] = useState(false)
    const [loading, setLoading] = useState(false)
    const [buying, setBuying] = useState(false)
    const [purchase, setPurchase] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    
    const [userId, setUserId] = useState(0);

    const [alert_ , setAlert] = useState({variant:"" , msg:""})
    const [showAlert , setShowAlert] = useState(false)

    const [acc , setAccounts] = useState([])
    const [ accountsSel , setAccountsSel] = useState()
    const handleRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handlePage = (event, newPage) => setPage(newPage);

    const HideModal = () => {
        setPopup(false)
    }

    const getPurchasedPlans = () => {
        setLoading(true)
        Api.get("user/purchase-plan-list")
            .then((res) => {
                setPackages(res.plan)

            })
            .catch(() => {

            })
            .finally(() => {
                setLoading(false)

                setPopup(false)
            })
    }

    
    const getAccountData = (id) => {
        setUserId(id)
        if (users?.hubspotAccounts) {
            let data = [];
            users.hubspotAccounts.map((item) => {
                if(!item.status){
                    data.push({ name: item.account_id })
                }
                // data.push({ name: item.account_id })
            })
            setAccounts(data)
            setPopup(true)
        }
    }

   

    const updatePurchasedAccount = () => { 
           setBuying(true)
        let ids = []
        accountsSel.forEach((e)=>{
            ids.push(parseInt(e.name))
        })
        Api.put("user/purchase/" + userId , {hubspotAccoutsIds: ids})
        .then((res) => {
            let data = []
            console.log("firat")
            res.PurchasedAccounts.forEach((e)=>{
                let newdata = e + ''
                data.push(newdata);
            })
            console.log("second")

            dispatch({type:"Plan_Purchased" , payload:data})
            console.log("third")

            setAlert({variant:"primary" , msg:"Account added succesfully!"})
            setShowAlert(true)
        })
        .catch(() => {
            setAlert({variant:"danger" , msg:"Some error occurred try again!"})
            setShowAlert(true)
        })
        .finally(() => {
            setBuying(false)
            setPopup(false)
            getPurchasedPlans()
            setAccountsSel([])
        }) 
    }

    useEffect(() => {
        getPurchasedPlans()
    }, [])


    return (  
        <>
        <PageTitle activeMenu="Purchased Plans" motherMenu="Dashboard" />
            <Modal show={popup} onHide={HideModal}  className="subscribe-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Add Accounts for Current plan</Modal.Title>
                    <br/>
                    
                </Modal.Header>
                <Modal.Body>
                <div className={"w-75"}> 
                    <Multiselect
                        placeholder={ " Account selected"}
                        showCheckbox
                        hideSelectedList
                        options={acc[0]?.name ? acc : [{ name: "No data" }]}
                        selectedValues={accountsSel}
                        onSelect={(val) => { setAccountsSel(val) }}
                        onRemove={(val) => { setAccountsSel(val) }}
                        displayValue="name"
                    />  
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={()=>updatePurchasedAccount(userId)} >
                        {buying && <div style={{ minWidth: '42px', justifyContent: "center", alignItems: "center" }}>
                            <Spinner
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </div> }
                         {!buying && "Add Accounts"}
                    </Button>
                </Modal.Footer>
            </Modal>
            <>
            {showAlert &&
            <Alert variant={alert_.variant}>{alert_.msg}</Alert>
            }
                <TableContainer>
                    <Table>
                        <TableHead className="thead">
                            <TableRow>
                                <TableCell className="th" component="th">Id</TableCell>
                                <TableCell className="th" component="th">License Number</TableCell>
                                <TableCell className="th" component="th">Purchased Plan</TableCell>
                                <TableCell className="th" component="th">Price</TableCell>
                                <TableCell className="th" component="th">No. of Accounts</TableCell>
                                <TableCell className="th" component="th">Content Type</TableCell>
                                <TableCell className="th" component="th">No. Of Query</TableCell>
                                <TableCell className="th" component="th">Query Result</TableCell>
                                <TableCell className="th" component="th">Connected Accounts</TableCell>
                                <TableCell className="th" component="th">Purchased at</TableCell>
                                <TableCell className="th" component="th">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className="tbody">

                            {loading ? <TableRow ><TableCell className="td" colSpan={100} scope="row" style={{ textAlign: "center" }}><br /><Spinner /><br /><br /></TableCell></TableRow>
                                :
                                <>
                                    {!packages?.length && <TableRow ><TableCell className="td" colSpan={100} scope="row" style={{ textAlign: "center" }}><br />No Plan Purchased<br /><br /></TableCell></TableRow>}
                                    {packages?.length > 0 && packages.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, i) => (
                                        <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                                            <TableCell className="td" scope="row">{item?.id}</TableCell>
                                            <TableCell className="td">{item?.license_number}</TableCell>
                                            <TableCell className="td" scope="row">{item?.plan_name}</TableCell>
                                            <TableCell className="td" scope="row">{item?.plan_price}</TableCell>
                                            <TableCell className="td" scope="row">{item?.no_of_accounts}</TableCell>
                                            <TableCell className="td" scope="row">{item?.content_type == "Y"? "Yes" : "No"}</TableCell>
                                            <TableCell className="td" scope="row">{item?.no_of_query == -1 ? "Unlimited" : item?.no_of_query}</TableCell>
                                            <TableCell className="td" scope="row">{item?.query_result}</TableCell>
                                            {/* <TableCell className="td" scope="row">{JSON.stringify(item?.hs_account_id).slice(1, JSON.stringify(item?.hs_account_id).length -1)}</TableCell> */}
                                            <TableCell className="td" scope="row">{item?.hs_account_id.map((item)=>{
                                                return <div>{item}</div>
                                            })}</TableCell> 
                                            <TableCell className="td">{item.created_at}</TableCell>
                                            <TableCell className="td">
                                                <IconButton aria-label="add" disabled={item?.add_status == false ? true : false }  color="success" size="large" onClick={item?.add_status == false ? "" : () => getAccountData(item.id)}>
                                                    <AddIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                  
                                </>}
                        </TableBody>
                    </Table>
                </TableContainer>
                {packages?.length > 0 && <TablePagination
                    className="pagination"
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={packages.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePage}
                    onRowsPerPageChange={handleRowsPerPage}
                />}
            </>

        </>
    )
}

export default PurchasedPlans