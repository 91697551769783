import { applyMutationToEventStore } from '@fullcalendar/react';
import {
    LOADING_TOGGLE_ACTION,
    LOGIN_CONFIRMED_ACTION,
    LOGIN_FAILED_ACTION,
    LOGOUT_ACTION,
    SIGNUP_CONFIRMED_ACTION,
    SIGNUP_FAILED_ACTION,
    PROFILE_UPDATE_ACTION,
    PASSWORD_UPDATE_FAILED_ACTION,
    PASSWORD_UPDATE_ACTION,
    PROFILE_UPDATE_FAILED_ACTION,
    HUBSPOT_VALIDATION,
    RESET_ERRORS
} from '../actions/AuthActions';

const initialState = {
    auth: {
        user: {},
        idToken: '',
        hubspotValidate:false
    },
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export function AuthReducer(state = initialState, action) {

    if(action.type === "loading_action"){
        return {
            ...state,
            errorMessage: '',
            successMessage: '',
            showLoading: true,
        };
    }

    if (action.type === SIGNUP_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: {
                user: action.payload.user,
                idToken: action.payload.access_token
            },
            errorMessage: '',
            successMessage: '',
            showLoading: false,
        };
    }
    if (action.type === LOGIN_CONFIRMED_ACTION) {
        return {
            ...state,
            auth:{
                user: action.payload.user,
                idToken: action.payload.access_token
            },
            errorMessage: '',
            successMessage: '',
            showLoading: false,
        };
    }

    if (action.type === LOGOUT_ACTION) {

        return {
            ...state,
            errorMessage: '',
            successMessage: '',
            auth: {
                user: {},
                idToken:"",
            },
        };
    }

    if (
        action.type === SIGNUP_FAILED_ACTION ||
        action.type === LOGIN_FAILED_ACTION
    ) {
        return {
            ...state,
            errorMessage: action.payload,
            successMessage: '',
            showLoading: false,
        };
    }

    if (action.type === LOADING_TOGGLE_ACTION) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
        // new Reducers
    if(action.type === PROFILE_UPDATE_ACTION){
        return {
            ...state,
            auth:{ ...state.auth, user: { ...state.auth.user,name:action.payload.name , email:action.payload.email}
            },
            errorMessage: '',
            successMessage: "Profile Updated successfully",
            showLoading: false,
        }
    }
    if(action.type === PROFILE_UPDATE_FAILED_ACTION){
        return {
            ...state,
            errorMessage: 'Some Error Occurred!',
            successMessage: '',
            showLoading: false,
        }
    }

    if(action.type === PASSWORD_UPDATE_FAILED_ACTION){
        return{
            ...state,
            errorMessage: action.payload,
            successMessage: "",
            showLoading:false
        }
    }
    if(action.type === PASSWORD_UPDATE_ACTION){
        return{
            ...state,
            errorMessage:"",
            successMessage:action.payload,
            showLoading:false
        }
    }
    if(action.type === RESET_ERRORS){
        return {
            ...state,
            errorMessage:"",
            successMessage: "",
            showLoading:false
        }
    }
    // if(action.type === PROFILE_UPDATE_FAILED_ACTION){
    //     // alert("Password updated")
    //     return{
    //         ...state,
    //         errorMessage:"Update failed...!",
    //         successMessage: "",
    //         showLoading:false
    //     }
    // }

    // if(action.type === HUBSPOT_VALIDATION){
        
    //     return {
    //         ...state,auth:{ ...state.auth, user: {...state.auth.user,hubspotConnected:true, hubspotAccounts: state.auth.user.hubspotAccounts.length?  [...state.auth.user.hubspotAccounts,action.payload] : [action.payload]}
    //         },
    //         errorMessage: '',
    //         successMessage: '',
    //         showLoading: false,
    //     }
    // }
    if(action.type === HUBSPOT_VALIDATION){
        
        return {
            ...state,auth:{ ...state.auth, user: {...state.auth.user,hubspotConnected:true, hubspotAccounts:action.payload.length?action.payload : false}
            },
            errorMessage: '',
            successMessage: '',
            showLoading: false,
        }
    }

    if(action.type === 'Plan_Purchased'){
        let purchasedPlans = []
        console.log(action.payload)

        let purchasedIds = action.payload
        state.auth.user?.hubspotAccounts?.forEach(e => {
            if(purchasedIds.includes(e.account_id)){
                e.status=true
                purchasedPlans.push(e)
            }else{
                purchasedPlans.push(e)
            }
        });
        // return {
        //     ...state,auth:{ ...state.auth, user: {...state.auth.user,hubspotAccounts:[]}
        //     },
        //     errorMessage: '',
        //     successMessage: '',
        //     showLoading: false,
        // }
        return state
    }

    return state;
}

    
