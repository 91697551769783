import React, { useContext, useEffect, useState } from 'react';
import Api from '../../../services/Api';
import OAuth2Login from 'react-simple-oauth2-login';
import Config from '../../../config.json';
import { IconButton } from "@material-ui/core";
import { Delete as DeleteIcon } from '@mui/icons-material';
import { Alert, Spinner, Modal, Button } from "react-bootstrap";

import { Link } from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

///Images
import small from "./../../../images/profile/small/pic1.jpg";
import avt1 from "./../../../images/avatar/1.jpg";
import avt2 from "./../../../images/avatar/2.jpg";
import avt3 from "./../../../images/avatar/3.jpg";
import avt4 from "./../../../images/avatar/4.jpg";
import avt5 from "./../../../images/avatar/5.jpg";
import avt6 from "./../../../images/avatar/6.jpg";


//Import Components
import { ThemeContext } from "../../../context/ThemeContext";

import DonutChart from './Dashboard/DonutChart';
import WidgetChart3 from './Dashboard/WidgetChart3';
import PreviousTransactions from './Dashboard/PreviousTransactions';
import NouiRangeSlider from './Dashboard/NouiRangeSlider';
import { useSelector, useDispatch } from 'react-redux';

import { LOGIN_CONFIRMED_ACTION, RESET_ERRORS } from '../../../store/actions/AuthActions';

import { HUBSPOT_VALIDATION } from '../../../store/actions/AuthActions';

//export const HUBSPOT_VALIDATION = `[Hubspot validation] hubspot validation`;

const Home = () => {

	const dispatch = useDispatch();
	let user = useSelector(state => state.auth.auth.user)
	let updateAlert = useSelector(state => state.auth)
	//useSelector(state => state.auth.auth.user.hubspotAccounts )

	const [show, setShow] = useState(false);
	const [loading, setLoading] = useState(false);
	const [deleting , setDeleating] = useState(false)
	const [success, setSuccess] = useState(false);
	const [err, setErr] = useState(false);
	const [resp, setResp] = useState({})
	const [hubspotAccounts, setHubspotAccounts] = useState([]);
	const [deleteItem, setDeleteItem] = useState('')
	const [alert_, setAlert] = useState({
		varient: "",
		msg: ""
	})
	const [showAlert, setShowAlert] = useState(false);

	 useEffect(() => {
		setHubspotAccounts(user.hubspotAccounts)
	}, [user])

	const onSuccess = async (response) => {
		console.log('Code',response.code)
		let res = await Api.post("hubspot/connect", { code: response.code, redirect_url: Config?.hubspot?.redirectUrl, id: user.id });
		if (res.hubspotConnected) {
			setAlert({
				varient: "success",
				msg: "Hubspot account connected Successfully !"
			})
			setShowAlert(true);
			dispatch({ type: HUBSPOT_VALIDATION, payload: res.hubspotAccounts })
		}
		else {
			setAlert({
				varient: "danger",
				msg: res.message + " !" || "Unable to connect to hubspot account try again !"
			})
			setShowAlert(true)
		}
		setLoading(false);
	}

	const onFailure = () => {
		setAlert({
			varient: "danger",
			msg: "Unable to connect with hubspot, try again !"
		})
		setShowAlert(true);
		setLoading(false);
	}


	const handleDelete = () => {

		setShowAlert(false);
		setDeleating(true)
		Api.get("user/hubspot/delete/" + deleteItem)
			.then((res) => {
				if (res.success) {
					setAlert({
						varient: "success",
						msg: res.message || "Hubspot account deleted successfully !"
					})
					setShowAlert(true);
					dispatch({ type: HUBSPOT_VALIDATION, payload: res.hubspotAccounts })
                    //alert(JSON.stringify(res))
				} else {
					setAlert({
						varient: "danger",
						msg: "Unable to delete hubspot account, try again !"
					})
					setShowAlert(true);
				}
			})
			.catch((err) => {
				setAlert({
					varient: "danger",
					msg: "Unable to delete hubspot account, try again !"
				})
				setShowAlert(true);

			})
			.finally(() => {
				setDeleating(false)
				setShow(false)
			})

	}

	const handleClose = () => {
		setShow(false)
	}

	useEffect(()=>{
		if(updateAlert.errorMessage !== ''){
			setAlert({
				varient: "danger",
				msg: updateAlert.errorMessage
			})
			setShowAlert(true);
			dispatch({type:RESET_ERRORS , payload:''});
		}

		if(updateAlert.successMessage !== ""){
			setAlert({
				varient: "success",
				msg: updateAlert.successMessage
			})
			setShowAlert(true);
			dispatch({type: RESET_ERRORS , payload:''});

		}
	},[])

	return (
		<>
			{showAlert && <Alert variant={alert_.varient}>{alert_.msg}</Alert>}
			<Modal show={show}>
				<Modal.Header><Modal.Title>Hubspot Account</Modal.Title></Modal.Header>
				<Modal.Body>Delete this hubspot account?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button variant="primary" onClick={handleDelete}>
						{deleting ?
							<div style={{minWidth:'42px' , justifyContent:"center" , alignItems:"center"}}>
								<Spinner
									animation="border"
									size="sm"
									role="status"
									aria-hidden="true"
								/>
							</div> :
							"Delete"}
					</Button>
				</Modal.Footer>
			</Modal>
			<div className='row row-cols-2 row-cols-sm-3 row-cols-md-4 '>

				{hubspotAccounts && hubspotAccounts.map((item) => {
					return (
						<div className='p-2 ' style={{ height: '150px' }} key={item.user_id}>
							<div className='bg-white shadow h-100 d-flex justify-content-evenly flex-column p-3  '>
								<div className='fs-5'>
									<strong>{item.domain}</strong>
								</div>
								<div className='d-flex justify-content-between align-items-center'>
									<div className='fs-5'><strong>Id: </strong>{item.account_id}</div>
									<div>
										<IconButton aria-label="delete" color="error" size="small" onClick={() => { setShow(true); setDeleteItem(item.id) }}>
											<DeleteIcon />
										</IconButton>
									</div>
								</div>
							</div>
						</div>
					)
				})}
				<OAuth2Login
					className="bg-transparent border border-0 p-0 connect-btn  "
					buttonText={
						<div className='p-2 ' style={{ height: '150px' }} onClick={() => {setShowAlert(false); setLoading(true);}}>
							<div className='border border-danger h-100  bg-danger shadow '>
								<div>
									<div><i className="bi bi-plus-circle" style={{ fontSize: "5rem" }}></i><div>{loading ? "Connecting to hubspot..." : "Connect to hubspot"}</div></div>
								</div>
							</div>
						</div>
					}
					authorizationUrl="https://app.hubspot.com/oauth/authorize"
					responseType="code"
					clientId={Config?.hubspot?.clientId}
					scope="content"
					redirectUri={Config?.hubspot?.redirectUrl}
					onSuccess={onSuccess}
					onFailure={onFailure}
					isCrossOrigin={true}
				/>

			</div>
		</>
	)
}
export default Home;