import React, { useEffect } from "react";
import { useState } from "react";
import PageTitle from "./../../layouts/PageTitle";
import axios from 'axios';
import Multiselect from 'multiselect-react-dropdown';
import OAuth2Login from 'react-simple-oauth2-login';
import { user_access } from "../../../services/AuthService";
import { ResponsiveContainer } from "recharts";
import { userAccess } from "../../../store/actions/AuthActions";
import { Alert , Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../services/Api";
import Config from '../../../config.json';
import { id } from "date-fns/locale";
import { Link } from "react-router-dom";

export const HUBSPOT_VALIDATION = `[Hubspot validation] hubspot validation`;


const Search = () => {

    const dispatch = useDispatch();

    let user = useSelector((state) => { return state.auth.auth.user })

    const [search, setSearch] = useState("");
    const [replace, setReplace] = useState("");
    const [searchResults, setSearchResults] = useState(null);
    const [ accountId, setAccountId ] = useState("");
    const [hubspotConnected, setHubspotConnected] = useState(user.hubspotConnected || false);
    const [state, setState] = useState({
        caseSensetive: false,
        urlOnly: false,
        urlAlso: false,
        metaAlso: false,
        metaOnly: false
    })

    const [sitePages, setSitePages] = useState(false);
    const [sitePagesSel, setSitePagesSel] = useState([]);

    const [blogs, setBlogs] = useState(false);
    const [content, setContentType] = useState(false);
    const [blogsSel, setBlogsSel] = useState([]);

    const [landingPages, setLandingPages] = useState(false);
    const [landingPagesSel, setLandingPagesSel] = useState([]);

    const [dryRun, setDryRun] = useState(false);

    const [landing, setLanding] = useState([])
    const [site, setSite] = useState([])
    const [blog, setBlog] = useState([])

    const [showForm, setShowForm] = useState(false)
    const [loading, setLoading] = useState(false)

    const [alert_, setAlert] = useState({
        varient: "",
        msg: ""
    })
    const [showAlert, setShowAlert] = useState(false)

    const [selectValues , setSelectValues] = useState([])

    let userData = useSelector(state=> state.auth.auth.user)

    useEffect(()=>{
        if(userData?.hubspotAccounts){
            console.log('not purchaged', userData.hubspotAccounts)
            setSelectValues(userData.hubspotAccounts)
        }
    },[])

    const hubspot_Connection = async (code) => {
        let res = await userAccess(code, dispatch);
        return res
    }

    const onFailure = () => {
        setAlert({
            varient: "danger",
            msg: "Unable to connect with hubspot, try again !"
        })
        setShowAlert(true);
        // setShowForm(false);
        setLoading(false);

    }

    const onSubmit = (e) => {
        setLoading(true);
        setShowAlert(false)
        e.preventDefault();
        setSearchResults(null);
        setShowAlert(false);
        let search_val = search.trim()
        let replace_val = replace.trim()

        let filters = []
        Object.entries(state).map(([key, val]) => {
            if (val) {
                filters.push(key)
            }
        });

        let blogData = blogsSel.map((item) => {
            return item.id
        })

        let siteData = sitePagesSel.map((item) => {
            return item.id
        })

        let LandingData = landingPagesSel.map((item) => {
            return item.id
        })

        Api.post("hubspot/replace/" + accountId, {
            search: search_val,
            replace: replace_val,
            dryRun,
            filters,
            list: {
                site_pages: { enable: sitePages, ids: sitePages ? siteData : [] },
                blogs: { enable: blogs, ids: blogs ? blogData : [] },
                landing_pages: { enable: landingPages, ids: landingPages ? LandingData : [] },
            }
        }).then((res) => {
            setLoading(false);
            //alert(JSON.stringify(res));
            if (res.success) {
                setSearchResults(res.message);
            } else {
                setLoading(false)
                setAlert({
                    varient: "danger",
                    msg: res.message || res.error
                })
                setShowAlert(true)
                window.scroll(0, 0);
            }
        })
    }

    const getData = async (id) => {
        setAccountId(id)
        setShowForm(false)
        setShowAlert(false)
        setLoading(true)
        Api.get("hubspot/data/" + id).then(res => {

            if (res?.list) {
                setLanding(res.list?.landing_pages || [])
                setLandingPagesSel(res.list?.landing_pages || [])

                setSite(res.list?.site_pages || [])
                setSitePagesSel(res.list?.site_pages || [])

                setBlog(res.list?.blogs || [])
                setBlogsSel(res.list?.blogs || [])
                if(res?.account_data?.content_type == "N") {
                    setContentType(true)
                }
                else {
                    setContentType(false)
                }
                // setAlert({
                //     varient: "success",
                //     msg: res.message || "Hubspot account connected successfully now you can search/replace words !"
                // })
                // setShowAlert(true)
            
                setShowForm(true)
            }else{
                setShowAlert(true)
                setAlert({
                    varient: "danger",
                    msg: res.message || res.error
                })
            }
            setLoading(false)
        });
    }

    useEffect(() => {
        setShowAlert(false);
    }, [search, replace, state, sitePages, blogs, landingPages, dryRun, landingPagesSel, sitePagesSel, blogsSel])

    return (
        <>
            <PageTitle activeMenu="Search & Replace" motherMenu="Dashboard" />

            {showAlert && <div className={`${alert_.msg == "Please purchase a subscription Plan for this account!" ?"d-flex  justify-content-between align-items-center" : ''}`}><Alert variant={alert_.varient}>{alert_.msg}</Alert> {alert_.msg == "Please purchase a subscription Plan for this account!" && <Link className='btn btn-primary p-3' style={{marginBottom:'1rem'}} to='/subscription'>Subscribe</Link>} </div>}
            {
            // !hubspotConnected ? <>
            //     {<div onClick={() => { setLoading(true); setShowAlert(false) }} style={{ display: "flex", justifyContent: "flex-end" }}>
            //         <OAuth2Login
            //             className="connect"
            //             buttonText={loading ? "Loading... connecting to hubspot" : 'Connect to hubspot'}
            //             authorizationUrl="https://app.hubspot.com/oauth/authorize"
            //             responseType="code"
            //             clientId={Config?.hubspot?.clientId}
            //             scope="content"
            //             redirectUri={Config?.hubspot?.redirectUrl}
            //             onSuccess={onSuccess}
            //             onFailure={onFailure}
            //             isCrossOrigin={true}
            //         />
            //     </div>
            //     }
            // </> :
                <>
                    <div className="authincation h-100">
                        <div className="row justify-content-center h-100 align-items-center">
                            <div className="col-md-9">
                                <div className="authincation-content">
                                    <div className="row no-gutters">
                                        <div className="col-xl-12">
                                            <div className="auth-form">
                                                <form onSubmit={onSubmit}>
                                                <div className="form-group-container">
                                                <label className="">
                                                            <strong>Select hubspot account</strong>
                                                        </label>
                                                        <select onChange={(e) => getData(e.target.value)} className="form-select p-3" defaultValue="">
                                                            <option style={{display:"none"}}></option>
                                                            {selectValues.map((item)=>{

                                                                // if(item.status){
                                                                //     return(
                                                                //         <option>{item.account_id}</option>
                                                                //     ) 
                                                                // }

                                                                return(
                                                                    <option>{item.account_id}</option>
                                                                ) 
                                                            })}
                                                        </select>
                                                        </div>
                                                        {loading && !showForm && <div style={{width:"fit-content" , margin:'auto' }}><Spinner/></div> }
                                                        {showForm && <>
                                                    <div className="form-group-container">
                                                        <label className="">
                                                            <strong>Search for</strong>
                                                        </label>
                                                        <input type="text" className="form-control"
                                                            placeholder="Search For"
                                                            value={search}
                                                            onChange={(e) => setSearch(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="form-group-container">
                                                        <label className="">
                                                            <strong>Replace with</strong>
                                                        </label>
                                                        <input type="text" className="form-control"
                                                            placeholder="Replace With"
                                                            value={replace}
                                                            onChange={(e) => setReplace(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="form-group-container-checkbox">
                                                        <label className="">
                                                            <strong>Filters</strong>
                                                        </label>
                                                        <div className="w-100">
                                                            <div>
                                                                <input type="checkbox"
                                                                    value={state.caseSensetive}
                                                                    onChange={(e) => {
                                                                        setState((prev) => {
                                                                            return { ...prev, caseSensetive: e.target.checked }
                                                                        })
                                                                    }}
                                                                /><label>Case-sensitive</label>
                                                            </div>
                                                            <div>
                                                                <input type="checkbox"
                                                                    value={state.urlalso}
                                                                    onChange={(e) => {
                                                                        setState((prev) => {
                                                                            return { ...prev, urlAlso: e.target.checked }
                                                                        })
                                                                    }}

                                                                /><label>Replace in urls also</label>
                                                            </div>
                                                            <div>
                                                                <input type="checkbox"
                                                                    value={state.urlOnly}
                                                                    onChange={(e) => {
                                                                        setState((prev) => {
                                                                            return { ...prev, urlOnly: e.target.checked }
                                                                        })
                                                                    }}

                                                                /><label>Replace in urls only</label>
                                                            </div>
                                                            <div>
                                                                <input type="checkbox"
                                                                    value={state.metaAlso}
                                                                    onChange={(e) => {
                                                                        setState((prev) => {
                                                                            return { ...prev, metaAlso: e.target.checked }
                                                                        })
                                                                    }}

                                                                /><label>Replace in metadata also</label>
                                                            </div>
                                                            <div>
                                                                <input type="checkbox"
                                                                    value={state.metaOnly}
                                                                    onChange={(e) => {
                                                                        setState((prev) => {
                                                                            return { ...prev, metaOnly: e.target.checked }
                                                                        })
                                                                    }}
                                                                /><label>Replace in metadata only</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group-container-checkbox">
                                                        <label className="">
                                                            <strong>Where To Search</strong>
                                                        </label>
                                                        <div className="w-100">
                                                            <div>
                                                                <input type="checkbox"
                                                                    value={sitePages}
                                                                    onChange={(e) => setSitePages(e.target.checked)}
                                                                /><label>Website pages</label>
                                                            </div>
                                                            { !content && sitePages && <div style={{ marginBottom: "10px" }}>
                                                                <Multiselect
                                                                    placeholder={!site.length ? "No Website pages" : site.length === sitePagesSel.length ? "All Selected" : "Type to Search"}
                                                                    showCheckbox
                                                                    disable={content}
                                                                    hideSelectedList
                                                                    options={site}
                                                                    selectedValues={sitePagesSel}
                                                                    onSelect={(val) => setSitePagesSel(val)}
                                                                    onRemove={(val) => setSitePagesSel(val)}
                                                                    displayValue="name"
                                                                /> 
                                                            </div>}
                                                            <div>
                                                                <input type="checkbox"
                                                                    value={blogs}
                                                                    onChange={(e) => setBlogs(e.target.checked)}
                                                                /><label>Blogs</label>
                                                            </div>
                                                            { !content && blogs && <div style={{ marginBottom: "10px" }}>
                                                                <Multiselect
                                                                    placeholder={!blog.length ? "No Blogs" : blog.length === blogsSel.length ? "All Selected" : "Type to Search"}
                                                                    showCheckbox
                                                                    hideSelectedList
                                                                    disable={content}
                                                                    options={blog}
                                                                    selectedValues={blogsSel}
                                                                    onSelect={(val) => setBlogsSel(val)}
                                                                    onRemove={(val) => setBlogsSel(val)}
                                                                    displayValue="name"
                                                                />
                                                            </div>}
                                                            <div>
                                                                <input type="checkbox"
                                                                    value={landingPages}
                                                                    onChange={(e) => setLandingPages(e.target.checked)}
                                                                /><label>Landing pages</label>
                                                            </div>
                                                            { !content && landingPages && <div style={{ marginBottom: "10px" }}>
                                                                <Multiselect
                                                                    placeholder={!landing.length ? "No Landing pages " : landing.length === landingPagesSel.length ? "All Selected" : "Type to Search"}
                                                                    showCheckbox
                                                                    hideSelectedList
                                                                    disable={content}
                                                                    options={landing}
                                                                    selectedValues={landingPagesSel}
                                                                    onSelect={(val) => setLandingPagesSel(val)}
                                                                    onRemove={(val) => setLandingPagesSel(val)}
                                                                    displayValue="name"
                                                                />
                                                            </div>}
                                                        </div>
                                                    </div>
                                                    <div className="form-group-container-checkbox">
                                                        <label className="">
                                                            <strong>Dry Run</strong>
                                                        </label>
                                                        <div className="w-100">
                                                            <input type="checkbox"
                                                                value={dryRun}
                                                                onChange={(e) => setDryRun(e.target.checked)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={"w-100 d-flex align-items-center " + (searchResults ? 'justify-content-between' : 'justify-content-end')}>
                                                        {searchResults && <div className="alert alert-success" style={{ width: "84%", borderRadius: 10, position: "relative", top: 6, fontSize: 14, color: "#000" }} dangerouslySetInnerHTML={{ __html: searchResults }} />}
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary btn-block w-auto"
                                                        >
                                                            {loading ? "Loading..." : "Submit"}
                                                        </button>
                                                    </div>
                                                        </>}

                                                </form>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default Search