import React, { Fragment, useState, useReducer , useEffect } from "react";
import PageTitle from "./../../layouts/PageTitle";
import { useDispatch , useSelector } from "react-redux";

import { updatePassword } from "../../../store/actions/AuthActions";
const Password = (props) => {

    const [oldPassword , setOldPassword] = useState("")
    const [newPassword , setNewPassword] = useState('')
    const [ConfirmPassword , setConfirmPassword] = useState('')
    const [err , setErr] = useState(false)

    const dispatch = useDispatch();

    const update_password =  (e)  => {
        e.preventDefault();

        if(newPassword === ConfirmPassword){
          
        updatePassword(  oldPassword ,newPassword , ConfirmPassword , props.history , dispatch)
        }else{
          setErr(true)
        }

    }

    const errors = useSelector((state)=> state.auth.errorMessage)

    useEffect(()=>{
      setErr(false)
    },[newPassword, oldPassword, ConfirmPassword])

    return (
        <Fragment>
          <PageTitle activeMenu="Update Password" motherMenu="Dashboard" />
          <div className="authincation h-100">
                <div className="row justify-content-center h-100 align-items-center">
                     <div className="col-md-6">
                         <div className="authincation-content">
                             <div className="row no-gutters">
                                 <div className="col-xl-12">
                                     <div className="auth-form">
                                      {err ? <div style={{marginBottom:'20px' , color:"red"}}>Confirm Password doesn't Match..!</div>:errors && <div style={{marginBottom:'20px' , color:"red"}}>Incorrect old password..!</div> }
                          
                                     <form onSubmit={update_password}>
                                     <div className="row">    
                                     {/* <div className="col-sm-6"> */}
                                     <div className="form-group mb-3">
                                     <label className="mb-1">
                                              <strong>Old Password</strong>
                                            </label>
                                            <input type="password" className="form-control"
                                               
                                              placeholder="Old Password"
                                              required
                                              onChange={(e)=>setOldPassword(e.target.value)}
    
                                            />
                                         </div>
                                         {/* </div>  */}
                                         {/* <div className="col-sm-6">
                                     <div className="form-group">
                                     <label className="mb-2 ">
                                              <strong>Last Name</strong>
                                            </label>
                                            <input type="name" className="form-control" 
                                              placeholder="Type Your Last Name"
                                            />
                                         </div>
                                         </div>         */}
                                        </div>
                                     <div className="form-group mb-3">
                                     <label className="mb-1">
                                              <strong>New Password</strong>
                                            </label>
                                            <input type="password" className="form-control" 
                                              placeholder="New Password"
                                              required
                                              onChange={(e)=>setNewPassword(e.target.value)}
                                            />
                                         </div>
                                     <div className="form-group mb-3">
                                     <label className="mb-1 ">
                                              <strong>Comfirm New Password</strong>
                                            </label>
                                            <input type="password" className="form-control" 
                                              placeholder="Confirm New Password"
                                              required
                                              onChange={(e)=>setConfirmPassword(e.target.value)}
                                            />
                                         </div>
                                         {/* <div className="form-group">
                                            <label className="mb-2 ">
                                                <strong>Set Password</strong>
                                                </label>
                                            <input type="password" className="form-control"
                                              placeholder="Type Your Password"
                                            />
                                          </div>
                                          <div className="form-group">
                                            <label className="mb-2 ">
                                                <strong>Confirm Password</strong>
                                                </label>
                                            <input type="password" className="form-control"
                                              placeholder="Type Your Password"
                                            />
                                          </div>*/}
                                          <div className="col-sm-3 my-3"> 
                                        <button
                                          type="submit"
                                          className="btn btn-primary btn-block"
                                        >
                                          Submit
                                        </button>
                                        {/* <input type="submit" className="btn btn-primary btn-block"/> */}
                                      </div>
                                     </form>
    
    
                                    </div>              
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
          
    
            
        </Fragment>
      );
}

export default Password