import { BarChart } from 'recharts';
import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
    update_profile,
    update_password,
    logout_user,
    user_access
} from '../../services/AuthService';
import { connect, useDispatch } from 'react-redux';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';

// added export
export const PROFILE_UPDATE_ACTION = `[Update action] profile updated`;
export const PASSWORD_UPDATE_FAILED_ACTION = `[Update password action] password update failed`;
export const PASSWORD_UPDATE_ACTION = `[Update password action] password update successfull`;
export const PROFILE_UPDATE_FAILED_ACTION = `[Update password action] Profile update Failed`;
export const HUBSPOT_VALIDATION = `[Hubspot validation] hubspot validation`;

export const RESET_ERRORS = `[RESET ERRORS]`


export function signupAction(name, email, password, password_confirmation, history) {
    return (dispatch) => {
        signUp(name, email, password, password_confirmation)
            .then((response) => {
                //alert(response.data.access_token);
                localStorage.setItem("invotoken" ,response.data.access_token)
                localStorage.setItem("invouser" , JSON.stringify(response.data.user))

                // saveTokenInLocalStorage(response.data);
                // runLogoutTimer(
                //     dispatch,
                //     response.data.expiresIn * 1000,
                //     history,
                // );
                dispatch(confirmedSignupAction(response.data));
                history.push('/');
            })
            .catch((err) => {
                console.log(err)
                dispatch(signupFailedAction("Email Already Exists!"));

            });
    };
}

export function resetErr(){
    return (dispatch) => {
        dispatch(resetErrors())
    }
}

export function logout(history) {
    return ((dispatch) => {

        logout_user()
            .then((res) => {
                localStorage.removeItem('invotoken');

                dispatch({
                    type: LOGOUT_ACTION,
                })
                history.push('/login');
            })
            .catch((err) => {

                // dispatch({
                //     type: LOGOUT_ACTION,
                // })
                // localStorage.removeItem('invotoken');
                // history.push('/login');
                // alert(
                //     'Logout Failed'
                // )
                console.log(err)
            }).finally(()=>{
                // localStorage.removeItem('invotoken');
                // history.push('/login');

            })
        return {
            type: LOGOUT_ACTION,
        };

    })

}

export function loginAction(email, password, history) {
    return (dispatch) => {
        login(email, password)
            .then((response) => {
                console.log(response)
                if (response.data.access_token) {
                    localStorage.setItem("invotoken" , response.data.access_token)
                    localStorage.setItem("invouser" , JSON.stringify(response.data.user.id))
                    // saveTokenInLocalStorage(response.data);
                    dispatch(loginConfirmedAction(response.data));
                    history.push('/');
                } else {
                    // alert("Please Enter Valid Email and Password.")
                    // const errorMessage = formatError(response.data);
                    dispatch(loginFailedAction("Invalid Email or password"));
                }
                // runLogoutTimer(
                //     dispatch,
                //     response.data.expiresIn * 1000,
                //     history,
                // );
                // dispatch(loginConfirmedAction(response.data));
                // history.push('/dashboard');                
            })
            .catch((error) => {
                //console.log(error);
                // const errorMessage = formatError(error.response.data);
                dispatch(loginFailedAction(error?.data?.message || "Error Occurred Try Again !"));
            });
    };
}

export function resetErrors(){
    return{
        type: RESET_ERRORS,
        payload:''
    }
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    // alert('auth actions')
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}

export function profileupdateaction(data) {
    return {
        type: PROFILE_UPDATE_ACTION,
        payload: data,
    };
}
export function passupdatefailed(data) {
    return {
        type: PASSWORD_UPDATE_FAILED_ACTION,
        payload: data,
    };
}
export function passwordupdatesuccess(data) {
    return {
        type: PASSWORD_UPDATE_ACTION,
        payload: data,
    };
}

// new exports

export function updateProfile(name, email, history) {
    return ((dispatch) => {

        update_profile(name, email)
            .then((response) => {


                // saveTokenInLocalStorage(response.data);
                // runLogoutTimer(
                //     dispatch,
                //     response.data.expiresIn * 1000,
                //     history,
                // );
                // dispatch(loginConfirmedAction(response.data));
                // let data = {name , email}

                dispatch({
                    type: PROFILE_UPDATE_ACTION,
                    payload: { name, email }
                });

                history.push('/');

                // dispatch(profileupdateaction(data))           
            })
            .catch((error) => {

                // alert(error.errors.password);
                //console.log(error);
                // const errorMessage = formatError(error.response.data);
                // dispatch({type:PROFILE_UPDATE_FAILED_ACTION});
            });
    })

}
export function updatePassword(old, newpass, confirmnew, history, dispatch) {
    // return (dispatch) => {
    update_password(old, newpass, confirmnew)
        .then((response) => {
            // console.log(response)
            if (response.data.status === 0) {
                // alert(response.data.message)
                dispatch(passupdatefailed("INCORRECT PASSWORD"));

            }
            else {

                dispatch(passwordupdatesuccess("Password Updated Successfully"))
                history.push('/');

            }
            // saveTokenInLocalStorage(response.data);
            // runLogoutTimer(
            //     dispatch,
            //     response.data.expiresIn * 1000,
            //     history,
            // );
            // dispatch(loginConfirmedAction(response.data));
            // history.push('/dashboard');                
        })
        .catch((error) => {
            // alert(error.errors.password)
            //console.log(error);
            // const errorMessage = formatError(error.response.data);
            dispatch(passupdatefailed("INCORRECT PASSWORD"));
        });
    // }
}

export async function userAccess (code , dispatch)  {

        let data = await user_access( code )
         alert(JSON.stringify(data.data.hubspotConnected));
        if(data.data.hubspotConnected){
            dispatch({type:HUBSPOT_VALIDATION})
            return "Successfully Connected!"
        }else{
            return "Authantication Failed!"
        }
    
}
