import React, { useState } from "react"
import { Spinner, Button, Modal, Alert } from "react-bootstrap"
import { Link } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle"
import Multiselect from "multiselect-react-dropdown"
import { useEffect } from "react"
import Api from "../../../services/Api"
import { useDispatch, useSelector } from "react-redux"
const Subscribe = () => {

    const [plans, setPlans] = useState([])
    const [popup, setPopup] = useState(false)
    const [accounts, setAccounts] = useState(false)
    const [loading, setLoading] = useState(false)
    const [accountsSel, setAccountsSel] = useState([])
    const [planDetails, setPlanDetails] = useState({})
    const [alert_ , setAlert] = useState({variant:"" , msg:""})
    const [showAlert , setShowAlert] = useState(false)
    const [selectionErr, setSelectionErr] = useState(false)
    const [buying, setBuying] = useState(false)

    let users = useSelector(state => state.auth.auth.user)
    const dispatch = useDispatch()

    const ShowModal = (val) => {
        setPlanDetails(val)
        setPopup(true)
    }


    const HideModal = () => {
        setPopup(false)
        setAccountsSel([])
        setPlanDetails({})
        setSelectionErr(false)
    }

    const getPlans = () => {
        setLoading(true)
        Api.get("plans/list")
            .then(res => setPlans(res.plan))
            .catch(err => {

            })
            .finally(() => setLoading(false))
    }


    useEffect(() => {
        getPlans()
        if (users?.hubspotAccounts) {
            let data = [];
            users.hubspotAccounts.map((item) => {
                if(!item.status){
                    data.push({ name: item.account_id })
                }
                //data.push({ name: item.account_id })
            })
            setAccounts(data)
        }
    }, [])

    const buyPlan = () => {
        setShowAlert(false)
        if (!accountsSel.length) {
            setSelectionErr(true);
        } else {
            setBuying(true)
            let ids = []
            accountsSel.forEach((e)=>{
                ids.push(parseInt(e.name))
            })
            Api.post("user/purchase-plan", { plan_id: planDetails.id, hs_account_id: ids })
                .then((res) => {
                    console.log(res)
                    let data = []
                    res.Plan.forEach((e)=>{
                        let newdata = e + ''
                        data.push(newdata);
                    })
                    dispatch({type:"Plan_Purchased" , payload:data})
                    setAlert({varient:"success" , msg:"Purchased successfully!"})
                    setShowAlert(true)
                })
                .catch(() => {
                    setAlert({varient:"danger" , msg:"Some error occurred try again!"})
                    setShowAlert(true)
                })
                .finally(() => {
                    setBuying(false)
                    HideModal()
                    if (users?.hubspotAccounts) {
                        let data = [];
                        users.hubspotAccounts.map((item) => {
                            if(!item.status){
                                data.push({ name: item.account_id })
                            }
                            //data.push({ name: item.account_id })
                        })
                        setAccounts(data)
                    }
                })
        }
    }

    return (
        <>
            <PageTitle activeMenu="Subscription" motherMenu="Dashboard" />

            <Modal show={popup} onHide={HideModal} className="subscribe-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Subscribe</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                { accounts.length? 
                <>
                    <div className="pb-2 fs-5">Select Accounts for <strong>{`${planDetails.name}`}</strong> plan</div>
                    {selectionErr && <div className="mb-2 text-danger">Please select atleast one hubspot account</div>}
                    <div className={"w-75"}> 
                    <Multiselect
                        placeholder={accountsSel.length + " / " + planDetails.no_of_accounts + " Account selected"}
                        showCheckbox
                        hideSelectedList
                        selectionLimit={accounts[0]?.name ? planDetails.no_of_accounts : 0}
                        options={accounts[0]?.name ? accounts : [{ name: "Nodata" }]}
                        selectedValues={accountsSel}
                        onSelect={(val) => { setAccountsSel(val); setSelectionErr(false) }}
                        onRemove={(val) => { setAccountsSel(val); setSelectionErr(false) }}
                        displayValue="name"
                    />
                    </div>
                    </>
                    :
                    <div>You doesn't have any unsubscribed account !</div>
}
                </Modal.Body>
                <Modal.Footer>
                    {accounts.length ?

                    <Button variant="primary" className="" onClick={() => buyPlan()}>
                        {buying ? <div style={{ minWidth: '42px', justifyContent: "center", alignItems: "center" }}>
                            <Spinner
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </div> : "Subscribe"}
                    </Button>
                    :
                   <Link to='/' className='btn btn-primary' >Connect to hubspot account</Link>
}
                </Modal.Footer>
            </Modal>

            {showAlert && <Alert variant={alert_.varient}>{alert_.msg}</Alert>}
            {loading ? <div style={{ minHeight: '50vh', display: 'grid', placeItems: "center" }} ><Spinner /></div>
            :<div className='row row-cols-2 row-cols-sm-3 row-cols-md-4 '>
                {plans.map((item, i) => {
                    if(item.status !== "Deactive")
                    return (
                        <div className='p-2 ' style={{ height: '200px', textAlign: "center" }} key={i}>
                            <div className='bg-white shadow h-100 d-flex justify-content-evenly flex-column p-2 '>
                                <div style={{ fontSize: 18 }}>
                                    <strong>{item.name}</strong>
                                </div>
                                <div className="fs-5">
                                    <div>Price: <strong>{item.price}</strong></div>
                                </div>
                                <div className="fs-5" >
                                    <div>No. of Accounts: <strong>{item.no_of_accounts}</strong></div>
                                </div>
                                <div className="fs-5" >
                                    <div>No. of Query: <strong>{item.no_of_query == -1? "Unlimited" : item.no_of_query }</strong></div>
                                </div>
                                <div className="fs-5" >
                                    <div>Content-Type <strong>{item.content_type == "Y"? "Yes" : "No"}</strong></div>
                                </div>
                                <button className=" btn btn-danger px-1 py-2 bg-danger rounded" onClick={() => ShowModal(item)}>Subscribe</button>
                            </div>
                        </div>
                    )
                })}
            </div>}
        </>
    )
}

export default Subscribe