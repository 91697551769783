import React, { useState, useEffect, useCallback } from 'react';
import { Button, Modal, Row, Col, Form, Spinner } from 'react-bootstrap';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, IconButton, Snackbar, Slide as SlideTransition, Alert } from "@material-ui/core";
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import Api from '../../../services/Api';
import PageTitle from '../../layouts/PageTitle';
// let authToken = null;

function MemberForm({ id, data, action, button, popupClose, setNotification, callback }) {

    // if( action === 'add' )
    //     data['permissions'] = ['reports'];

    var [userData, setUserData] = useState(data);
    var [UpdateuserData, setupdateUserData] = useState([]);
    var [validated, setValidated] = useState(false);
    var [allowSubmit, setAllowSubmit] = useState(action !== 'update');
    var [isProcessing, setProcessing] = useState(false);
    var [error, setError] = useState(false);
    var [errors, setErrors] = useState({});
    var permissions = { "reports": "View Reports", "robots": "View Robots", "billing": "View Billings" };

    const validate = () => {
        if (userData.email && !/[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,20}/i.test(userData.email)) {
            errors['email'] = "Email address is not valid";
        }
     }
    // if (action === "create") {
    //     if (!userData.user_type) {

    //         setUserData((prev) => {
    //             return { ...prev, user_type: "U" }
    //         })
    //     }
    // }

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const form = e.currentTarget;
        errors = {};
        setError(false);

        if (action !== 'delete')
            validate();

        var valid = action === 'delete' || (form.checkValidity() && Object.keys(errors).length === 0);

            if(UpdateuserData.name){
                userData['name'] = UpdateuserData.name;
                setUserData(userData);
            }
            if(UpdateuserData.email){
                userData['email'] = UpdateuserData.email;
                setUserData(userData);
            }
            if(UpdateuserData.password){
                //alert("pass")
                userData['password'] = UpdateuserData.password;
                setUserData(userData);
            }
            if(UpdateuserData.password_confirmation){
                userData['password_confirmation'] = UpdateuserData.password_confirmation;
                setUserData(userData);
            }
            if(UpdateuserData.user_type){
                //alert(UpdateuserData.user_type)
                userData['user_type'] = UpdateuserData.user_type;
                setUserData(userData);
            }else if(!userData.user_type){
                userData.user_type = "U"
                setUserData(userData);
            }
            if(UpdateuserData.status == 0){
                userData['status'] = UpdateuserData.status;
                setUserData(userData);
            }
            if(UpdateuserData.status == 1){
                userData['status'] = UpdateuserData.status;
                setUserData(userData);
            }

            if(action==="update" || action === "create"){
                if(userData.password !== userData.password_confirmation){
                    setError("confirm password doesn't match!")
                    return
                }
            }



        if (valid) {

            setProcessing(true);
            // Api.setToken(authToken);

            Api.call(
                action === 'delete' ? 'get' : action === "create" ? 'post' : action === "update" ? "post" : "get",
                'user/' + action + (['update', 'delete'].includes(action) ? '/' + id : ''),
                userData
            ).then(res => {
                if (res.success) {
                    console.log(res)
                    if (action === 'create') {

                        if (callback) callback(action, userData, res.data || {});
                        setProcessing(false);
                        popupClose();
                        setNotification("User added successfully");
                    } else if (action === "update") {
                        setProcessing(false);
                        popupClose();
                        setNotification("User updated successfully");
                    }

                    else {
                        setProcessing(false);
                        popupClose();
                        setNotification(action === 'delete' ? "User deleted successfully" : "User updated successfully");
                        if (callback) callback(action, userData, res.data || {});
                    }

                } else {
                    setProcessing(false);
                    setError(res.error);
                }
            })
            .catch((err)=>{
                //setNotification(action === 'delete' ? "User deleted successfully" : "User updated successfully");

            })
            .finally(()=>{
                setupdateUserData({})
            });

        }

        setErrors(errors);
        setValidated(true);
    }

    const saveCheckboxData = (name, value, checked) => {
        value = value.trim();

        if (!userData[name])
            userData[name] = [];

        if (checked)
            userData[name].push(value);
        else
            userData[name] = userData[name].filter((prms) => { return prms !== value });

        if (!userData[name].length)
            delete userData[name];

        setUserData(userData);
        setAllowSubmit(true);
    }

    const saveFieldData = (name, value) => {
        //alert( name + ' '  + value)
        if (typeof value === "number") {

            UpdateuserData[name] = value
            setupdateUserData(UpdateuserData);
            setAllowSubmit(true);
        } else {

            UpdateuserData[name] = value.trim();
            if (UpdateuserData[name] === '')
                delete UpdateuserData[name];

                setupdateUserData(UpdateuserData);
            setAllowSubmit(true);
        }
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>

            <Modal.Body>
                <div className="mt-0 m-2">
                    {error && <Alert severity="error" className="mb-3">{error} !</Alert>}
                    {action !== 'delete' && <>
                        <Row className="mb-3">
                            <Form.Group as={Col} xs>
                                <Form.Label>Name</Form.Label>
                                <Form.Control required type="text" onChange={e => saveFieldData('name', e.target.value)} defaultValue={data?.name || ""} />
                                <Form.Control.Feedback type="invalid">Name is required.</Form.Control.Feedback>
                            </Form.Group>

                        </Row>
                        <Form.Group className="mb-3">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control required type="email" onChange={e => saveFieldData('email', e.target.value)} defaultValue={data?.email || ""} />
                            <Form.Control.Feedback type="invalid">{errors.email || "Email address is required."}</Form.Control.Feedback>
                        </Form.Group>

                        <Row className="mb-3">
                            <Form.Group as={Col} xs >
                                <Form.Label>Password</Form.Label>
                                <Form.Control  type="password" onChange={e => saveFieldData('password', e.target.value)} defaultValue={""} />
                                {/* <Form.Control.Feedback type="invalid">{errors.Password || "Password is required."}</Form.Control.Feedback> */}
                            </Form.Group>
                            <Form.Group as={Col} xs>
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control  type="password" onChange={e => saveFieldData('password_confirmation', e.target.value)} defaultValue={""} />
                                {/* <Form.Control.Feedback type="invalid">{errors.Password || "Confirm Password is required."}</Form.Control.Feedback> */}
                            </Form.Group>
                        </Row>

                        <Row className='mb-3'>

                            <Form.Group as={Col} xs>
                                <Form.Label>User Type</Form.Label>
                                <select className='form-select p-3' onChange={(e) => saveFieldData("user_type", e.target.value === "Admin" ? "A" : "U")} defaultValue={data?.user_type === "A" ? "Admin" : "User"}>
                                    <option>User</option>
                                    <option>Admin</option>
                                </select>
                            </Form.Group>
                            <Form.Group as={Col} xs>
                                <Form.Label>Status</Form.Label>
                                <select className='form-select p-3' onChange={(e) => saveFieldData("status", e.target.value === "Activate" ? 1 : 0)} defaultValue={data?.status == 0 ? "Deactivate" : "Activate"}>
                                    <option>Activate</option>
                                    <option>Deactivate</option>
                                </select>
                            </Form.Group>
                        </Row>

                    </>}
                    {action === 'delete' && <p className="m-0">Are you sure to delete this user?</p>}
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" type="submit" >
                {/* disabled={isProcessing || !allowSubmit} */}
                    {isProcessing && <Spinner className="mx-3" as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                    {!isProcessing && <span>{button}</span>}
                </Button>
            </Modal.Footer>
        </Form>
    );
}

function NewMemberPopup({ callback, setNotification }) {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant="primary" onClick={handleShow} className="heading-topright">Add New</Button>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New User</Modal.Title>
                </Modal.Header>
                <MemberForm id={0} action="create" data={{}} button="Add User" popupClose={handleClose} setNotification={setNotification} callback={callback} />
            </Modal>
        </>
    );
}

function EditMemberPopup({ data, action, show, handleClose, callback, setNotification }) {
    console.log(data)
    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ textTransform: "capitalize" }} >{action} user: {data?.name || null}</Modal.Title>
                </Modal.Header>
                <MemberForm id={data?.id || 0} action={action} data={data || {}} button={action} setNotification={setNotification} callback={callback} popupClose={handleClose} />
            </Modal>
        </>
    );
}

function ShowNotification({ message, open, handleClose, type, time }) {
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={open || false}
            onClose={handleClose}
            TransitionComponent={SlideTransition}
            autoHideDuration={time || 10000}
        >
            <Alert onClose={handleClose} severity={type || "success"} sx={{ width: '100%' }}>{message || null}</Alert>
        </Snackbar>
    );
}

function MemberList({ items = [], setNotification, callback ,loading}) {
    //console.log(items);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [showPopup, setShowPopup] = useState(false);
    const [formAction, setFormAction] = useState('update');

    const [formData, setFormData] = useState();

    const handlePopupClose = () => setShowPopup(false);
    const handlePage = (event, newPage) => setPage(newPage);

    const handleRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const memberActions = (action, data) => {
        setFormAction(action);
        setFormData(data);
        setShowPopup(true);
    }

    const handleCallback = (action, data, resp) => {
        if (action !== 'update') setPage(0);
        callback(action, data, resp);
    }

    return (
        <>
            <EditMemberPopup data={formData} action={formAction} show={showPopup} handleClose={handlePopupClose} callback={handleCallback} setNotification={setNotification} />
            <TableContainer>
                <Table>
                    <TableHead className="thead">
                        <TableRow>
                            <TableCell className="th" component="th">Id</TableCell>
                            <TableCell className="th" component="th">Name</TableCell>
                            <TableCell className="th" component="th">Email</TableCell>
                            <TableCell className="th" component="th">User Type</TableCell>
                            <TableCell className="th" component="th">Status</TableCell>
                            <TableCell className="th" component="th" align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="tbody w-100 ">
                        {loading ? <TableRow ><TableCell className="td" colSpan={100} scope="row" style={{ textAlign: "center" }}><br /><Spinner/><br /><br /></TableCell></TableRow> :
                            <>
                                {!items.length && <TableRow ><TableCell className="td" colSpan={100} scope="row" style={{ textAlign: "center" }}><br />No Users<br /><br /></TableCell></TableRow>}
                                {items?.length > 0 && items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, i) => (
                                    <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                                        <TableCell className="td" scope="row">{item.id}</TableCell>
                                        <TableCell className="td" scope="row">{item.name}</TableCell>
                                        <TableCell className="td">{item.email}</TableCell>
                                        <TableCell className="td">{item.user_type === "A" ? "Admin" : "User"}</TableCell>
                                        <TableCell className="td" style={{ textTransform: 'capitalize' }}>{item.status == 1 ? "Activated" : "Deactivated"}</TableCell>
                                        <TableCell className="td" align="right">
                                            {item.id === 1 ? <></> : <IconButton aria-label="delete" color="error" size="small" onClick={() => memberActions('delete', item)}>
                                                <DeleteIcon />
                                            </IconButton>}
                                            <IconButton aria-label="edit" color="primary" size="small" onClick={() => memberActions('update', item)}>
                                                <EditIcon />
                                            </IconButton>

                                        </TableCell>
                                    </TableRow>

                                ))}
                            </>}
                    </TableBody>
                </Table>
            </TableContainer>
            {items?.length > 0 && <TablePagination
                className="pagination"
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={items.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePage}
                onRowsPerPageChange={handleRowsPerPage}
            />}
        </>
    )

}

function Team(props) {

    const [notification, setNotification] = useState(null);
    const hideNotification = () => setNotification(null);
    const [delIds, setDelIds] = useState([]);
    var [isLoaded, setLoaded] = useState(false);
    let [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(false);

    const getMembers = useCallback(() => {
        // Api.setToken(authToken);
        setLoading(true)
        Api.get('users').then(res => {
            console.log(res)
            if (res.success) {
                setMembers(res.user);
            }
        })
        .catch(()=>{

        })
        .finally(()=>{
            setLoading(false)
        })
    }, [props?.userData?.id]);

    useEffect(() => {
        if (!isLoaded) {
            //authToken = props?.userData?.token;
            getMembers();
            setLoaded(true);
        }
    }, [props?.userData?.token, isLoaded, setLoaded, getMembers]);

    const handleCallback = (action, data, resp) => {
        var i = 0;
        if (action === 'delete' && data.id) {
            delIds.push(data.id);
            setDelIds(delIds);
            var newData = [];
            for (i = 0; i < members.length; i++) {
                if (members[i].id !== data.id)
                    newData.push(members[i]);
            }
            members = newData;
        }

        if (action === 'update' && data.id) {
            for (i = 0; i < members.length; i++) {
                if (members[i].id === data.id) {
                    members[i] = data;
                }
            }
        }

        if (action === 'create')
            getMembers();
        else
            setMembers(members);
    }

    return (
        <div>
            <PageTitle activeMenu="Users" motherMenu="Dashboard" />

            <div className='d-flex justify-content-between align-items-center mb-3'>
                <h2>Users</h2>
                <NewMemberPopup setNotification={setNotification} callback={handleCallback} />
            </div>
            <ShowNotification open={notification ? true : false} message={notification} handleClose={hideNotification} />
            <MemberList items={members} setNotification={setNotification} callback={handleCallback} loading={loading} />
        </div>
    );
}

export default Team;