import React, { useEffect, useState } from "react";
import Api from "../../../services/Api";
import { Alert } from "react-bootstrap";
import { useSelector , useDispatch } from "react-redux";
import { RESET_ERRORS } from "../../../store/actions/AuthActions";
const AdminHome = () => {
    const dispatch = useDispatch();
    const [usersData , setUserData] = useState([])
    const [alert_, setAlert] = useState({
		varient: "",
		msg: ""
	})
	const [showAlert, setShowAlert] = useState(false);
	let updateAlert = useSelector(state => state.auth)


    const getAdminData = () => {
        Api.get("admin/count")
        .then((res)=>{
            console.log(res)
            setUserData(res)
        })
    }
    
	useEffect(()=>{
        getAdminData();

		if(updateAlert.errorMessage !== ''){
			setAlert({
				varient: "danger",
				msg: updateAlert.errorMessage
			})
			setShowAlert(true);
			dispatch({type:RESET_ERRORS , payload:''});
		}

		if(updateAlert.successMessage !== ""){
			setAlert({
				varient: "success",
				msg: updateAlert.successMessage
			})
			setShowAlert(true);
			dispatch({type: RESET_ERRORS , payload:''});

		}
	},[])

    return (
        <>
			{showAlert && <Alert variant={alert_.varient}>{alert_.msg}</Alert>}

        <div style={{ display: "flex", flexWrap: 'wrap' , justifyContent:"flex-start" ,flexDirection: 'column'}}>
            <div className='bg-white shadow h-100 p-2 m-2'>
                <div style={{ fontSize: 15 }}>
                   Total number of users: <strong>{usersData.userCount}</strong>
                </div>
            </div>
            <div className='bg-white shadow h-100 p-2 m-2'>
                <div style={{ fontSize: 15 }}>
                   Total Hubspot accounts connected: <strong>{usersData.hubspotCounts}</strong>
                </div>
            </div>
            <div className='bg-white shadow h-100 p-2 m-2'>
                <div style={{ fontSize: 15 }} >
                    <div> Total purchased plans: <strong>{usersData.purchaseCounts}</strong></div>
                </div>
            </div>
        </div>
        </>
    )
}

export default AdminHome